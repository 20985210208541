

































































































































import ProgramsAutoComplete from "@/components/Inputs/ProgramsAutoComplete.vue";
import SportangoWeekPicker from "@/components/Inputs/SportangoWeekPicker.vue";
import UsersAutoComplete from "@/components/Navigation/UsersAutoComplete.vue";
import CashPaymentButton from "@/components/Payments/CashPaymentButton.vue";
import DropInMobilePaymentItem from "@/components/Payments/DropInMobilePaymentItem.vue";
import EditableAmount from "@/components/Payments/EditableAmount.vue";
import PaymentStatus from "@/components/Payments/PaymentStatus.vue";
import TablePaymentMethod from "@/components/Payments/TablePaymentMethod.vue";
import { WatchLoading } from "@/decorators/Loading";
import { DB } from "@/firebase";
import { CurrentUserMixin, LoadingMixin } from "@/mixins/Helpers";
import { SharedPaymentIntents, SharedPaymentMethods } from "@/mixins/Payments";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { getEventsQuery } from "@/store/actions/events";
import { DropInPaymentViewItem } from "@/types/Payment";
import { Header } from "@/types/Table";
import { fromFirestore } from "@/utils/parser";
import {
  getCorrectStatusAndMessage,
  getRightConvenienceFee,
  getRightPaymentMethod,
  parsePaymentDate
} from "@/utils/payments";
import {
  collection,
  onSnapshot,
  query,
  QueryConstraint,
  QuerySnapshot,
  where
} from "@firebase/firestore";
import { Unsubscribe } from "@firebase/util";
import {
  BaseUser,
  Event,
  EVENTS_TABLE_NAME,
  StripeMerchantInfo,
  Transaction,
  TransactionCustomer
} from "@sportango/backend";
import dayjs from "dayjs";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";

@Component({
  name: "run-drop-in-payments",
  components: {
    DropInMobilePaymentItem,
    ProgramsAutoComplete,
    UsersAutoComplete,
    SportangoWeekPicker,
    PaymentStatus,
    CashPaymentButton,
    TablePaymentMethod,
    EditableAmount
  }
})
export default class RunDropInPayments extends mixins(
  LoadingMixin,
  CurrentUserMixin,
  SharedPaymentIntents,
  SharedPaymentMethods,
  ResponsiveMixin
) {
  isLoading = true;
  selectedPlayers: Array<DropInPaymentViewItem> = [];
  amountEditors: Record<string, number> = {};
  eventSubscription: Unsubscribe | null = null;
  startDate: Date = new Date();
  selectedProgram = "";
  selectedPlayer = "";

  get headers(): Array<Header<DropInPaymentViewItem>> {
    return [
      {
        value: "customerName",
        text: "Player"
      },
      {
        value: "programName",
        text: "Program",
        sortable: false
      },
      {
        value: "lessonDate",
        text: "Program Date",
        sortable: true,
        width: "140px"
      },
      {
        value: "amount",
        text: "Amount",
        align: "center",
        sortable: true,
        width: "150px"
      },
      {
        value: "convenienceFee",
        text: "Fee",
        sortable: false,
        align: "center"
      },
      {
        value: "total",
        text: "Total",
        sortable: false,
        align: "center"
      },
      {
        value: "status",
        text: "Status",
        align: "center",
        sortable: false
      },
      {
        value: "paymentMethod",
        text: "Method",
        sortable: false,
        align: "center",
        width: "100px"
      },
      {
        value: "paidInCash",
        text: "Cash",
        align: "center",
        sortable: false,
        width: "100px"
      },
      {
        value: "paymentDateShort",
        text: "Payment Date",
        align: "center",
        sortable: true
      }
    ];
  }

  get items(): Array<DropInPaymentViewItem> {
    return this.eventPlayers
      .map((p) => {
        const transaction = this.$store.getters.transactions.find(
          (t) => t.parentItem === p.event.id
        );
        const transactionCustomer = transaction?.customers?.find(
          (c) => c.uid === p.uid
        );
        const paymentIntent = this.paymentIntents.find(
          (pI) => pI.id === transactionCustomer?.paymentIntentId
        );
        const program = this.$store.getters.programs.find(
          (pR) => pR.id === p.event.parentItem
        );
        const { status, statusMessage } = getCorrectStatusAndMessage(
          transactionCustomer,
          paymentIntent
        );
        const { short, full } = parsePaymentDate(
          paymentIntent?.created,
          transactionCustomer
        );
        const paymentMethod =
          this.paymentMethods.find(
            (pM) => pM.id === getRightPaymentMethod(paymentIntent || null)
          ) || null;
        const defaultPaymentMethod =
          this.defaultPaymentMethods.find(
            (d) => d.customer === p.stripeCustomerId
          ) || null;
        const tableItem: DropInPaymentViewItem = {
          customerName: p.displayName || p.email || "",
          amount: this.calculateAmountForPlayer(p.event, transactionCustomer),
          convenienceFee: 0,
          total: this.calculateAmountForPlayer(p.event, transactionCustomer),
          paymentMethod: paymentMethod || defaultPaymentMethod || null,
          status,
          statusMessage,
          paymentDateShort: short,
          paymentDate: full,
          uid: p.uid,
          disabled: (() => {
            if (p.stripeCustomerId && (defaultPaymentMethod || paymentMethod)) {
              if (
                status === "failed" ||
                status === "notStarted" ||
                status === "cancelled"
              ) {
                return false;
              }
            }
            if (this.isLoading) {
              return true;
            }
            return true;
          })(),
          stripeCustomerId: p.stripeCustomerId,
          isPaymentRunning: (() => {
            if (transactionCustomer) {
              if (transactionCustomer.paidInCash) {
                return false;
              }
              if (paymentIntent === undefined) {
                switch (transactionCustomer.status) {
                  case "success":
                  case "failed":
                  case "missingStripeCustomerId":
                  case "missingPaymentMethod":
                  case "cancelled":
                  case "requiresAttention":
                  case "notStarted":
                    return false;
                  case "processing":
                    return true;
                  default:
                    return false;
                }
              }
            }
            return false;
          })(),
          date: paymentIntent?.created || 0,
          defaultPaymentMethod: defaultPaymentMethod,
          itemId: `${p.uid}-${p.event.id}`,
          paidInCash: transactionCustomer?.paidInCash,
          lessonDate: dayjs(p.event.startTime).format("MM/DD/YYYY"),
          lessonDateNumber: p.event.startTime ? Number(p.event.startTime) : 0,
          transaction,
          event: p.event,
          programName: program?.name
        };
        if (typeof this.amountEditors[tableItem.itemId] === "number") {
          tableItem.amount = Number(this.amountEditors[tableItem.itemId]);
        }
        tableItem.convenienceFee = getRightConvenienceFee(
          tableItem.status,
          tableItem.amount,
          tableItem.defaultPaymentMethod,
          transactionCustomer,
          this.$store.getters.merchantInfo
        );
        tableItem.total = tableItem.amount + tableItem.convenienceFee;
        return tableItem;
      })
      .sort((a, b) => b.lessonDateNumber - a.lessonDateNumber);
  }

  get allowedSelectedPlayers(): DropInPaymentViewItem[] {
    return this.selectedPlayers.filter((s) => !s.disabled);
  }

  get eventTransactionMap(): Record<string, Transaction> {
    const results: Record<string, Transaction> = {};
    this.$store.getters.events.forEach((e) => {
      if (e.id) {
        if (!results[e.id]) {
          const eventTransaction = this.$store.getters.transactions.find(
            (t) => t.parentItem === e.id
          );
          if (eventTransaction) {
            results[e.id] = eventTransaction;
          }
        }
      }
    });
    return results;
  }

  get eventPlayersMap(): Record<string, BaseUser[]> {
    const results: Record<string, BaseUser[]> = {};
    this.$store.getters.events.forEach((e) => {
      if (e.id) {
        if (!results[e.id]) {
          const eventPlayers = this.$store.getters.users.filter((u) =>
            e.playerIds?.includes(u.uid)
          );
          if (eventPlayers.length > 0) {
            results[e.id] = eventPlayers;
          }
        }
      }
    });
    return results;
  }

  get existingSelectedTransactions(): Record<string, Transaction> {
    return this.calculateExistingSelectedTransaction();
  }
  get newSelectedTransactions(): Record<string, Transaction> {
    return this.calculateNewSelectedTransaction();
  }

  get showRunButton(): boolean {
    return (
      this.items.filter((i) => !i?.disabled && !i?.isPaymentRunning).length > 0
    );
  }

  get eventPlayers(): Array<BaseUser & { event: Event }> {
    const results: Array<BaseUser & { event: Event }> = [];
    this.$store.getters.events.forEach((e) => {
      e.players?.forEach((p) => {
        const playerInfo = this.$store.getters.users.find(
          (u) => u.uid === p.uid
        );
        if (
          e.players?.find((p) => p.uid === playerInfo?.uid)?.playerType !==
          "DROP_IN"
        ) {
          return;
        }
        if (playerInfo && p.hasAttended) {
          if (this.playerToFilter) {
            if (playerInfo.uid !== this.playerToFilter) {
              return;
            }
          }
          results.push({
            ...playerInfo,
            event: e
          });
        }
      });
    });
    return results;
  }

  get merchantInfo(): StripeMerchantInfo | undefined {
    return this.$store.getters.merchantInfo;
  }

  get programToFilter(): string | null {
    if (this.selectedProgram && this.selectedProgram.length > 0) {
      return this.selectedProgram;
    }
    return null;
  }

  get playerToFilter(): string | null {
    if (this.currentUser?.permissions.hasAdminAccess) {
      if (this.selectedPlayer && this.selectedPlayer.length > 0) {
        return this.selectedPlayer;
      }
    } else if (this.currentUser?.permissions.hasPlayerAccess) {
      return this.currentUser.uid;
    }
    return null;
  }

  get noDataMessage(): string {
    if (this.programToFilter) {
      return "No Programs found";
    } else {
      return "Please select a program";
    }
  }

  changeAmount(itemId: string, amount: number) {
    if (this.amountEditors[itemId]) {
      delete this.amountEditors[itemId];
    }
    this.amountEditors = {
      ...this.amountEditors,
      [itemId]: amount
    };
    this.amountEditors[itemId] = amount;
  }

  calculateExistingSelectedTransaction(
    selectedItems = this.allowedSelectedPlayers
  ) {
    const existingTransactions: Record<string, Transaction> = {};
    selectedItems.forEach((p) => {
      if (p.event.id) {
        // check if existingTransactions contains event
        if (this.eventTransactionMap[p.event.id]) {
          // Transaction for this event already exists
          if (!existingTransactions[p.event.id]) {
            existingTransactions[p.event.id] =
              this.eventTransactionMap[p.event.id];
          }
        }
      }
    });
    return existingTransactions;
  }

  calculateNewSelectedTransaction(selectedItems = this.allowedSelectedPlayers) {
    const newTransactions: Record<string, Transaction> = {};
    selectedItems.forEach((p) => {
      if (p.event.id) {
        if (!this.eventTransactionMap[p.event.id]) {
          if (!newTransactions[p.event.id]) {
            let description = `Drop In on ${dayjs(p.event.startTime).format(
              "MM/DD/YYYY"
            )}`;
            const program = this.$store.getters.programs.find(
              (pR) => pR.id === p.event.parentItem
            );
            if (program) {
              description += ` for ${program.name}`;
            }
            newTransactions[p.event.id] = {
              parentItem: p.event.id,
              transactionType: "drop-ins",
              merchantId: this.merchantInfo?.merchantId,
              isRunning: true,
              description
            };
          }
        }
      }
    });
    return newTransactions;
  }

  calculateAmountForPlayer(
    event: Event,
    transactionCustomer?: TransactionCustomer
  ): number {
    if (transactionCustomer?.amount) {
      return transactionCustomer.amount;
    }
    const program = this.$store.getters.programs.find(
      (p) => p.id === event.parentItem
    );
    return program?.lessonPrice || 0;
  }

  async changeCashStatus(uid: string, item: DropInPaymentViewItem) {
    this.isLoading = true;
    const existingSelectedTransactions =
      this.calculateExistingSelectedTransaction([item]);
    let newSelectedTransactions = this.calculateNewSelectedTransaction([item]);
    if (item.event.id) {
      if (existingSelectedTransactions[item.event.id]) {
        // transaction already exists for event
        const playerEventTransaction =
          existingSelectedTransactions[item.event.id];
        if (playerEventTransaction.customers?.find((c) => c.uid === item.uid)) {
          // transaction already has customer
          playerEventTransaction.customers =
            playerEventTransaction.customers.map((c) => {
              if (c.uid === item.uid) {
                return {
                  ...c,
                  retry: false,
                  amount: item.amount,
                  convenienceFee: item.convenienceFee,
                  paidInCash: !c.paidInCash,
                  cashPaidDate: Number(new Date())
                };
              }
              return c;
            });
        } else {
          // transaction exists but customer not in it
          playerEventTransaction.customers = [
            ...(playerEventTransaction.customers || []),
            {
              uid: item.uid,
              amount: item.amount,
              id: item.stripeCustomerId,
              convenienceFee: item.convenienceFee,
              paidInCash: true,
              cashPaidDate: Number(new Date())
            }
          ];
        }
        existingSelectedTransactions[item.event.id] = {
          ...playerEventTransaction
        };
        newSelectedTransactions = {};
      } else if (newSelectedTransactions[item.event.id]) {
        // transaction is new for event
        newSelectedTransactions[item.event.id] = {
          ...newSelectedTransactions[item.event.id],
          customers: [
            ...(newSelectedTransactions[item.event.id].customers || []),
            {
              uid: item.uid,
              amount: item.amount,
              id: item.stripeCustomerId,
              convenienceFee: item.convenienceFee,
              paidInCash: true,
              cashPaidDate: Number(new Date())
            }
          ]
        };
      }
    }
    await this.save(existingSelectedTransactions, newSelectedTransactions);
    this.selectedPlayers = [];
  }

  async runIndividualPayment(item: DropInPaymentViewItem) {
    this.isLoading = true;
    this.selectedPlayers = [item];
    await this.runPayment();
  }

  async runPayment() {
    this.isLoading = true;
    this.allowedSelectedPlayers.forEach((p) => {
      if (p.event.id) {
        if (this.existingSelectedTransactions[p.event.id]) {
          // transaction already exists for event
          const playerEventTransaction =
            this.existingSelectedTransactions[p.event.id];
          if (playerEventTransaction.customers?.find((c) => c.uid === p.uid)) {
            // transaction already has customer
            playerEventTransaction.customers =
              playerEventTransaction.customers.map((c) => {
                if (c.uid === p.uid) {
                  return {
                    ...c,
                    retry: true,
                    amount: p.amount,
                    convenienceFee: p.convenienceFee,
                    description: `${p.programName}, Drop-in on ${dayjs(
                      p.event.startTime
                    ).format("MM/DD")}, ${p.customerName}`
                  };
                }
                return c;
              });
          } else {
            // transaction exists but customer not in it
            playerEventTransaction.customers = [
              ...(playerEventTransaction.customers || []),
              {
                uid: p.uid,
                amount: p.amount,
                id: p.stripeCustomerId,
                convenienceFee: p.convenienceFee,
                description: `${p.programName}, Drop-in on ${dayjs(
                  p.event.startTime
                ).format("MM/DD")}, ${p.customerName}`
              }
            ];
          }
          this.existingSelectedTransactions[p.event.id] = {
            ...playerEventTransaction
          };
        } else if (this.newSelectedTransactions[p.event.id]) {
          // transaction is new for event
          this.newSelectedTransactions[p.event.id] = {
            ...this.newSelectedTransactions[p.event.id],
            customers: [
              ...(this.newSelectedTransactions[p.event.id].customers || []),
              {
                uid: p.uid,
                amount: p.amount,
                id: p.stripeCustomerId,
                convenienceFee: p.convenienceFee,
                description: `${p.programName}, Drop-in on ${dayjs(
                  p.event.startTime
                ).format("MM/DD")}, ${p.customerName}`
              }
            ]
          };
        }
      }
    });
    await this.save();
    this.selectedPlayers = [];
  }

  async save(
    existingTransactions: Record<string, Transaction> = this
      .existingSelectedTransactions,
    newTransactions: Record<string, Transaction> = this.newSelectedTransactions
  ) {
    await Promise.all([
      ...Object.keys(existingTransactions).map((key) =>
        this.$store.dispatch("updateTransaction", existingTransactions[key])
      ),
      ...Object.keys(newTransactions).map((key) =>
        this.$store.dispatch("createTransaction", newTransactions[key])
      )
    ]);
  }

  async mounted() {
    this.startEventWatch();
    this.$store.commit("events", []);
  }

  @Watch("merchantInfo")
  @Watch("startDate")
  @Watch("programToFilter")
  @Watch("playerToFilter")
  startEventWatch() {
    if (this.eventSubscription) {
      this.eventSubscription();
    }
    const queries: QueryConstraint[] = [
      where("eventType", "==", "PROGRAM"),
      ...getEventsQuery({
        type: "week",
        refDate: this.startDate.toISOString()
      })
    ];
    if (this.programToFilter) {
      queries.push(where("parentItem", "==", this.programToFilter));
    }
    this.eventSubscription = onSnapshot(
      query(collection(DB, EVENTS_TABLE_NAME), ...queries),
      this.watchEvents
    );
  }

  @WatchLoading()
  async watchEvents({ docs }: QuerySnapshot) {
    if (this.merchantInfo?.merchantId) {
      const events = docs.map((d) => fromFirestore<Event>(d, "id"));
      this.$store.commit("events", events);
      await this.$store.dispatch(
        "getProgramById",
        events.map((e) => e.parentItem)
      );
      this.$store.commit("transactions", []);
      await this.$store.dispatch(
        "getTransactionsForParentItems",
        this.$store.getters.events.map((e) => e.id)
      );
      let players: string[] = [];
      this.$store.getters.events.forEach((e) => {
        players = [...players, ...(e.playerIds || [])];
      });
      await this.$store.dispatch("getUsersById", players);
      await this.getDefaultPaymentMethods(
        Array.from(
          new Set(
            this.eventPlayers
              .map((eP) => eP.stripeCustomerId || "")
              .filter((s) => s !== "")
          )
        ),
        this.merchantInfo.merchantId
      );
      if (this.$store.getters.transactions.length > 0) {
        await this.getPaymentIntents(this.$store.getters.transactions);
        await this.getPaymentMethodsForIntents(this.paymentIntents);
      }
    }
  }

  /** Unsubscribe from onSnapshot watching event paid */
  beforeDestroy() {
    if (this.eventSubscription) {
      this.eventSubscription();
    }
  }
}
